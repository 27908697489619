
 
.trade-line__time {
	width: 16%;
	display: flex; 
}

.trade-line__market,
.trade-line__from,
.trade-line__to,
.trade-line__type,
.trade-line__price  {
	width: 12%;
}
   
.trade-line__fee  {
	width: 12%;
	display: flex;
	justify-content: flex-end;
}

.trade-line__status {
	display: flex;
	justify-content: flex-end;
	width: 10%;
} 

.history-line__time {
	width: 11%;
}

.history-line__coin {
	width: 9%;
}

.history-line__type {
	width: 12%;
}

.history-line__amount {
	width: 18%;
}

.history-line__destination {
	width: 23%;
}

.history-line__trxId {
	width: 16%;
}

.history-line__status {
	display: flex;
	justify-content: flex-end;
	width: 11%;
} 
