.wallet.avatar-rounded{
	height: auto;
	background: transparent;
	border-radius: 50%;
}


.wallet.asset-avatar.small {
	width: 1.2rem;
	height: 1.2rem;
}

.wallet.asset-avatar.medium {
	width: 1.5rem;
	height: 1.5rem;
}

.wallet.asset-avatar.normal {
	width: 2rem;
	height: 2rem;
}

.wallet.asset-avatar.regular {
	width: 2.625rem;
	height: 2.625rem;
}

.wallet.asset-avatar.large {
	width: 3.375rem;
	height: 3.375rem;
}

.wallet.avatar-rounded.vault{
	background: #7e7e7e2b;
	border-radius: 50%;
}

.wallet.avatar-rounded.vault.small {
	height: 1.2rem;
	font-size: 0.613rem;
}

.wallet.avatar-rounded.vault.medium {
	height: 1.5rem;
	font-size: 0.75rem;
}

.wallet.avatar-rounded.vault.large {
	height: 3.375rem;
	font-size: 1.5rem;
}

.wallet.avatar-rounded.vault.regular {
	height: 2.625rem;
	font-size: 1.25rem;
}

.wallet.avatar-rounded.card{ 
	border-radius: 10%;
	width: 40px;
	height: 25px;
}