.vault-list {
	padding-left: 2rem;
	padding-right: 2rem;
}

.vault-line {
	padding-top: 1rem;
	padding-bottom: 1rem;
}

.vault-list__plan {
	width: 30%;
	display: flex;
	align-items: center;
}

.vault-list__apr {
	width: 25%;
}

.vault-list__duration {
	width: 25%;
}


.vault-list__subscripe {
	display: flex;
	justify-content: flex-end;
	width: 20%;
}


.vault-deposits-list__time {
	width: 10%;
}

.vault-deposits-list__plan {
	width: 12%;
}

.vault-deposits-list__apr {
	width: 10%;
}

.vault-deposits-list__duration {
	width: 10%;
}

.vault-deposits-list__asset {
	width: 10%;
}

.vault-deposits-list__amount {
	width: 10%;
}

.vault-deposits-list__autoSubscribe {
	width: 10%;
}

.vault-deposits-list__repayment {
	width: 10%;
}

.vault-deposits-list__request {
	width: 10%;
}

.vault-deposits-list__status {
	width: 8%;
}