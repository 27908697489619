.qr-box { 
	width: 298px;
	height: 280px; 
} 

.qr-box-border {
	border-radius: 16px;
}

.qr-box-opacity { 
	width: 280px;
	height: 280px;
	position: relative;
	margin-left: auto;
	margin-right: auto; 
}
 
.qr-box-opacity::before {
	content: "";
	display: block;
	background-image: url('@assets/images/QrPLaceholder.png');
	position: absolute;
	width: 280px;
	height: 280px;
	opacity: 0.1;
	background-repeat: no-repeat;
	background-size: cover; 
	cursor: pointer;
}


@media only screen and (min-width: 600px) {
	.login-input .MuiOutlinedInput-root.MuiInputBase-root {
		height: 2.625rem !important;
	}

	.registration-input .MuiOutlinedInput-root.MuiInputBase-root {
		height: 2.625rem !important;
	}

	.registration-input.MuiOutlinedInput-root.MuiInputBase-root {
		height: 2.625rem !important;
	}

}

.login-input.password input {
	-webkit-text-security: disc;
}




.registration-input.country-selection div.MuiOutlinedInput-root {
	align-content: center;
}

#auth {
	display: flex;
	min-width: 100%;
	min-height: 100%;
}