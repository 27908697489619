#portal-app-bar-id {
	transition: none;
	height: inherit;
	display: flex;
	align-items: center;
	justify-content: center;
}

#portal-app-bar-id .lang-drop-box {
	padding: 0 0.5rem 0 0.5rem;
}

#portal-app-bar-id .account-box {
	padding: 0 0.5rem 0 0;
	display: flex;
	align-items: center;
}

#portal-app-bar-id .main-toolbar {
	display: flex;
	align-items: center;
}

#portal-app-bar-id .basic-toolbar {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 100%;
}

.navbar-logo {
	height: 42px;
	max-width: 105px;
}

@media only screen and (min-width: 900px) {
	.navbar-logo {
		margin-left: 35px;
	}
}
