.transaction-line {
	min-height: 3rem;
	align-items: center;
}

.transaction-icon {
	width: 4rem;
	height: 4rem;
	display: flex;
	justify-content: center;
	align-items: center;
}

.transaction-icon svg{
	width: 1em;
	height: 1em;
}

@media only screen and (max-width: 900px) {
	.transaction-icon {
		width: 60px;
		height: 60px;
	}
}
