.labelWrap {
	display: flex;
	align-items: center;
}

.mainIcon {
	height: 50px !important;
	width: 50px !important;
}

.labelIcon {
	margin-right: 0.25em;
	font-size: 1rem !important;
}

.closeIcon {
	position: absolute !important;
	right: 0;
}

.dialog.loading {
	filter: blur(2px);
}

.loading-spinner {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 95%;
	position: absolute;
}