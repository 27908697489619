#root {
	min-height: 100vh;
	/* display: flex; */
}

body, html {
	min-height: 100vh;
}

@media only screen and (max-width: 767px) {
	#root .ui.secondary.pointing.menu .item {
		padding: 12px 0;
		font-size: larger;
		margin-right: 16px;
	}
}

/* remove browser default outlines from all elements*/
*:focus {
	outline: 0 !important;
}


.grecaptcha-badge {
	visibility: hidden;
}