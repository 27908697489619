.g-recaptcha-bubble-arrow
{
    display: none;
}

.g-recaptcha-bubble-arrow + div
{
    position: fixed !important;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    background: aqua;
}