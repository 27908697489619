.token-balance {
	padding-left: 2rem;
	padding-right: 2rem;
}

.token-line {
	padding-top: 1rem;
	padding-bottom: 1rem;
}

.token-list__asset {
	width: 24%;
	display: flex;
	align-items: center;
}

.token-list__balance {
	width: 24%;
}

.token-list__available_balance {
	width: 24%;
}

.token-list__value {
	width: 18%;
}

.token-list__more {
	display: flex;
	justify-content: flex-end;
	width: 10%;
}

#token-summary-line {
	height: 4.375rem;
	align-items: center;
	display: flex;
	margin-bottom: 2px;
}

