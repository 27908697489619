#token-trade .trade-direction {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 2;
}


#token-trade .MuiSlider-markLabel {
	font-weight: 400;
	font-size: 0.625rem;
}


#token-trade .flex-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	align-content: center;
	flex: 1;
	width: 100%;
	height: 100%;
}


#token-trade .token-value-input input {
	text-align: right;
	font-weight: 400;
	padding-left: 0px;
	padding-right: 0px;
}

#token-trade .token-value-input .MuiOutlinedInput-root { 
	padding-right: 14px;
}

#token-trade .token-value-input input::placeholder {
	font-size: 1.625rem;

}

#token-trade .token-input {
	height: 100%;
	display: flex;
	justify-content: center;
}

#token-trade .token-input .asset-label-style .MuiTypography-root {
	font-size: 1.125rem;
	font-weight: 400;
}

#token-trade .trade-container {
	position: relative;
	display: flex;
	flex: 1;
	flex-wrap: wrap;
	min-height: 210px;
	max-height: 447px;

}



#token-trade .asset-select {
	border-radius: 10px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

#token-trade .asset-input .MuiOutlinedInput-root {
	border: unset;
	height: 100%;
	font-size: 1.625rem
}

#token-trade .from-asset-id,
#token-trade .to-asset-id {
	display: flex;
	flex: 1;
	position: absolute;
	height: 100px;
	width: 100%;
}

#token-trade .from-asset-id {
	top: 0;
	left: 0;
}

#token-trade .to-asset-id {
	bottom: 0;
	left: 0;
}


@keyframes move-asset-down {
	0% {
		top: 0;
		bottom: auto;
	}

	10% {
		top: -20%;
		transform: scale(1.2);
		z-index: 1;
	}

	70% {
		top: 55%;
		transform: scale(1.1);
		z-index: 1;
	}

	90% {
		top: auto;
		bottom: 0;
		transform: scale(1.0);
		z-index: 1;
	}

	100% {
		top: auto;
		bottom: 0;
		transform: scale(1.0);
	}
}

@keyframes move-asset-up {

	0% {
		bottom: 0;
		top: auto;
	}


	70% {
		bottom: 35%;
	}

	70% {
		bottom: 55%;
	}

	100% {
		top: 0;
		bottom: auto;
	}
}


#token-trade .move-down {

	-webkit-animation: move-asset-down 1s 0s ease-in forwards;
	animation: move-asset-down 1s 0s ease-in forwards;
}

#token-trade .move-up {
	-webkit-animation: move-asset-up 1s 0s ease-in forwards;
	animation: move-asset-up 1s 0s ease-in forwards;
}