.product-option-box { 
 	width: 240px; 
	 height: 100%;
	/* height: 140px;    */
	min-width: 240px;
	min-height: 140px;   
}


.product-option-box .product-option-box__item-wrap {
	padding: 1.5rem; 
	display: flex;
	flex: 1; 
	flex-direction: column; 
	justify-content: space-between; 
}