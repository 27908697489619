
/* .form-template .mainArea {
	height: 90%; 
} */

.form-template .buttonArea {
	height: 10%;
	align-self: flex-end;
}

.customDialogButton {
	width: 45%;
	min-height: 2.6rem;
}
  