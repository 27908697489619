#multipleButtonContent {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	padding-left: 12px;
	padding-right: 12px;
	margin: 0px 24px 24px 24px;
}

#oneButtonContent {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	padding-left: 12px;
	padding-right: 12px;
	margin: 0px 24px 24px 24px;
}