.graph {
    margin-bottom: 1em;
}



.graph tbody th {
    text-align: right;
}

@supports (display:grid) {

    .graph {
        display: block;
        width: 100%;
        height: 280px;
    }

    .graph caption {
        display: block;
    }

    .graph thead {
        display: none;
    }

    .graph tbody {
        position: relative;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(2em, 1fr));
        column-gap: 2.5%;
        align-items: end;
        height: 100%;
        margin: 3em 0 1em 2.8em;
        padding: 0 1em;
        border-bottom: 2px solid rgba(170, 170, 170, 0.5);
        background: repeating-linear-gradient(180deg,
        rgba(170, 170, 170, 0.2) 0,
        rgba(170, 170, 170, 0.2) 1px,
        transparent 1px,
        transparent 20%);
    }

    .graph tbody:before,
    .graph tbody:after {
        position: absolute;
        left: -3.2em;
        width: 2.8em;
        text-align: right;
        font: bold 80%/120% arial, helvetica, sans-serif;
    }

    .graph tr {
        position: relative;
        display: block;
    }

    .graph tr:hover {
        z-index: 999;
    }

    .graph th,
    .graph td {
        display: block;
        text-align: center;
    }

    .graph tbody th {
        position: absolute;
        top: -3em;
        left: 0;
        width: 100%;
        font-weight: normal;
        text-align: center;
        white-space: nowrap;
        text-indent: 0;
        transform: rotate(-45deg);
    }

    .graph td {
        width: 20px;
        height: 100%;
        border-radius: 5px 5px 0 0;
        transition: background 0.5s;
        margin-left: auto;
        margin-right: auto;
    }

    .graph tr:hover td {
        opacity: 0.7;
    }

    .graph td p {
        overflow: hidden;
        position: absolute;
        left: 50%;
        bottom: -30px;
        padding: 0.5em 0;
        margin: -1em 0 0;
        font: normal 85%/120% arial, helvetica, sans-serif;
        font-weight: bold;
        opacity: 1;
        transition: opacity 0.5s;
        width: 4em;
        margin-left: -2em;
    }

    .toggleGraph:checked+table td span,
    .graph tr:hover td span {
        width: 4em;
        margin-left: -2em;
        /* 1/2 the declared width */
        opacity: 1;
    }


}



.ticks {
    position: relative;
    top: -303px;
    left: 16px;
    width: 3%;
    height: 280px;
    z-index: 1;
    margin-bottom: -280px;
    font-size: 10px;
}

.ticks .tick {
    height: 20%;
    margin: 0;
    position: relative;
}

.chart-scroll-icon {
    cursor: pointer;
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    box-shadow: 0 1px 15px 2px rgba(217, 14, 232, 0.41);
}

.chart-scroll-icon.disabled{
    cursor: unset;
    background: rgba(170, 170, 170, 0.5) !important;
    box-shadow: unset !important;
}


.chart-scroll-icon:after {
    content: '';
    display: inline-block;
    margin-top: 1.3rem;
    margin-left: 1.3rem;
    width: 0.4rem;
    height: 0.4rem;
    border-top: 0.1rem solid white;
    border-right: 0.1rem solid white;
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.scroll-left:after {
    -moz-transform: rotate(225deg);
    -webkit-transform: rotate(225deg);
    transform: rotate(225deg);
}

.scroll-right:after {
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
