.cryto-asset .crypto-line,
.cryto-asset.crypto-line {
	padding-top: 1rem;
	padding-bottom: 1rem;
}


.cryto-asset .crypto__asset,
.cryto-asset .crypto__price,
.cryto-asset .crypto__balance,
.cryto-asset .crypto__portfolio {
	width: 17%;
}


.cryto-asset .crypto__actions {
	width: 32%;
	text-align: right;
}

@media only screen and (max-width: 900px) {

	.cryto-asset .crypto__asset,
	.cryto-asset .crypto__price,
	.cryto-asset .crypto__balance,
	.cryto-asset .crypto__portfolio {
		width: 25%;
	}
	.cryto-asset .crypto__actions {
		width: 100%;
		text-align: right;
		margin-top: 5px;
	}
}