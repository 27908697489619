 #langSelect {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 12px;
	border: none;
	background: transparent;

}

#flagIcon {
	min-width: 20px;
}