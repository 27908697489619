.business-cards-list__name {
    width: 22%;
}

.business-cards-list__card-holder {
    width:22%;
}

.business-cards-list__balance {
    width: 14%;
}

.business-cards-list__balance-on-hold {
    width: 14%;
}

.business-cards-list__actions {
    width: 10%;
    cursor: default !important;
}

.business-cards-list__status{
	width: 10%; 
}

@media only screen and (max-width: 600px) {
    .business-cards-list__name {
        width: 35%;
    }

    .business-cards-list__card-holder {
        width:35%;
    }

    .business-cards-list__balance {
        width: 30%;
    }

    .business-cards-list__balance-on-hold {
        display: none;
    }

    .business-cards-list__actions {
        display: none;
    }

    .business-cards-list__status{
        display: none;
    }
}

.business-cards-list-value {
    font-weight: 400 !important;
    font-size: 13px !important;
    line-height: 19px !important;
}
