#help-topic-step .help-topic-card-content {
	padding: 20px;
	height: 6.625rem;
}

#help-topic-step .help-topic-title {
	font-size: 0.75rem;
	text-align: center;
	margin-top: 0.2rem;
	margin-bottom: 0rem;
}

#help-topic-step .help-topic-title-container {
	display: flex;
	flex-direction: column;
	flex: 1;
	justify-content: center;
}

#help-topic-step .help-topic-icon {
	text-align: center;
}