/*Change border when dropdown list is open  */
.MuiInputBase-root.Mui-focused div[aria-expanded="true"] {
	border-radius: 16px 16px 16px 16px;
}

/*Hide part, when dropdown is selected */
.styledSelect .select-hide-Item {
	display: none; 
}

.select-hide-Item { 
	margin-top: 0.2rem;
	font-weight: 400;
	font-size: 0.75rem;
	line-height: 1rem;
}

/*item in drop down*/
 .select-primary-Item  {
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 1rem;
}  

/* selected item in input field */
.styledSelect  .select-primary-Item  {
	font-weight: 400;
	font-size: 0.938rem;
	line-height: 1.4rem;
}  