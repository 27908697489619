 /* Workaround for Firefox
 blur form area*/
 @supports not (backdrop-filter: blur(99px)) {
 	.blurred-content {
 		filter: blur(99px);
 	}
 }

 /* Workaround for Firefox
 blur whole background window */
 @supports not (backdrop-filter: blur(4px)) {
 	.muiDashboard.blurred-window {
 		filter: blur(4px);
 		height: 100vh;
 		width: 100wv;
 	}
 }

 @media only screen and (max-width: 900px) {
	
 }