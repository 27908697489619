.portal-loading {
	width: 42px;
	height: 42px;
	margin: 1px auto;
	position: relative;
}

.portal-loading.qr {
	width: 200px;
	height: 200px;
	display: flex;
    justify-content: center;
    align-items: center;
}

.portal-loading.dialog {
	display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 95%;
    position: absolute;
}

.portal-loading.dialog div{
	margin-left: auto;
	margin-right: auto;
} 

.portal-loading.bar div {
	width: 6px;
	height: 16px;
	background: #7E7E7E;
	border-radius: 4px;
	position: absolute; 
	left: 21px;
	opacity: 0.05;

	-webkit-animation: fadeit 1.1s linear infinite;
	animation: fadeit 1.1s linear infinite;
}

.portal-loading.bar.qr div {
	left: unset;
}

@-webkit-keyframes fadeit {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

@keyframes fadeit {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}


.portal-loading.bar div:nth-child(1) {
	-webkit-transform: rotate(0deg) translate(0, -16px);
	transform: rotate(0deg) translate(0, -16px);

	-webkit-animation-delay: 0.39s;
	animation-delay: 0.39s;
}

.portal-loading.bar div:nth-child(2) {
	-webkit-transform: rotate(45deg) translate(0, -16px);
	transform: rotate(45deg) translate(0, -16px);

	-webkit-animation-delay: 0.52s;
	animation-delay: 0.52s;
}

.portal-loading.bar div:nth-child(3) {
	-webkit-transform: rotate(90deg) translate(0, -16px);
	transform: rotate(90deg) translate(0, -16px);

	-webkit-animation-delay: 0.65s;
	animation-delay: 0.65s;
}

.portal-loading.bar div:nth-child(4) {
	-webkit-transform: rotate(135deg) translate(0, -16px);
	transform: rotate(135deg) translate(0, -16px);

	-webkit-animation-delay: 0.78s;
	animation-delay: 0.78s;
}

.portal-loading.bar div:nth-child(5) {
	-webkit-transform: rotate(180deg) translate(0, -16px);
	transform: rotate(180deg) translate(0, -16px);

	-webkit-animation-delay: 0.91s;
	animation-delay: 0.91s;
}

.portal-loading.bar div:nth-child(6) {
	-webkit-transform: rotate(225deg) translate(0, -16px);
	transform: rotate(225deg) translate(0, -16px);

	-webkit-animation-delay: 1.04s;
	animation-delay: 1.04s;
}

.portal-loading.bar div:nth-child(7) {
	-webkit-transform: rotate(270deg) translate(0, -16px);
	transform: rotate(270deg) translate(0, -16px);

	-webkit-animation-delay: 1.17s;
	animation-delay: 1.17s;
}

.portal-loading.bar div:nth-child(8) {
	-webkit-transform: rotate(315deg) translate(0, -16px);
	transform: rotate(315deg) translate(0, -16px);

	-webkit-animation-delay: 1.3s;
	animation-delay: 1.3s;
}