#faq-message-step .help-topic-card {  
	width: 8.25rem;
	height:  fit-content;
}

#faq-message-step .help-topic-card-content { 
	height: 100%;  
	padding: 0.5rem; 
}

#faq-message-step  .help-topic-title {
	font-size: 0.75rem;
	text-align: center;
	margin-top: 0rem;
	margin-bottom: 0rem;
}

#faq-message-step .help-topic-icon {
	text-align: center; 
} 
