.form-container__content {
	height: 80vh; 
	justify-content: center;
	display: flex;
	padding: 0rem 2.6rem;
}

.form-menu__left {
	display: flex;
	flex-direction: column;
	margin-left: 1.75rem !important;
	margin-right: 0.875rem !important;
	padding-top: 0 !important;
}


.form-pick-product-list {
	display: flex; 
	flex-wrap: wrap;  
	justify-content: flex-start; 
	align-items: flex-start;
	height: inherit;
}