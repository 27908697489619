.cover-main-wrap {
	position: relative;
	height: inherit;
	width: inherit;
	max-height: inherit;
	max-width: inherit;
	min-height: inherit;
	min-width: inherit;
}



.cover-child-wrap {
	position: relative;
	z-index: 4;
	height: inherit;
	width: inherit;
	max-height: inherit;
	max-width: inherit;
	min-height: inherit;
	min-width: inherit;

}


.cover-container {
	height: inherit;
	width: inherit;
	max-height: inherit;
	max-width: inherit;
	overflow: hidden;
	position: absolute;
	z-index: 0;
}

.eclipse1 {
	/* 	margin: -5px -10px -10px -5px; */
	transform: rotate(140deg);
	position: absolute;
	border-radius: 50%;
	width: 40%;
	height: 100%;
	z-index: 1;
	top: 5%;
	left: 5%;
	filter: blur(50px); 
	-webkit-filter: blur(50px);
	-moz-filter: blur(50px);
	-o-filter: blur(50px);
	-ms-filter: blur(50px);
}

.eclipse2 {
	/* margin: -5px -10px -10px -5px; */
	transform: rotate(140deg);
	position: absolute;
	border-radius: 50%;
	width: 40%;
	height: 100%;
	z-index: 2;
	top: 1%;
	right: 5%; 
	filter: blur(50px);
	-webkit-filter: blur(50px);
	-moz-filter: blur(50px);
	-o-filter: blur(50px);
	-ms-filter: blur(50px);
}