 #option-cover-box .option-cover-box__item-wrap {
   width: inherit;
   height: inherit;
 }

 #option-cover-box.earnStatisticsCard {
   width: 100%;
   height: 100%;
 } 
 
 #option-cover-box.planCard {
   width: 100%;
   height: 150px;
   margin-right: 20px;
   opacity: 0.4;
   cursor: pointer;
  }

 #option-cover-box.planCard.selected {
    opacity: 1;
    margin-right: 0;
  }

  #option-cover-box.planCard.hiddenButton {
    opacity: 1;
    margin-right: 0;
  }

  #option-cover-box.planCard.selected .cover-container {
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }