#onfido-mount {
	top: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex: 1;
	flex: 1;
	max-height: 75vh;
	flex: 1; 
	max-height: 75vh;
	height: 100%;
}

#onfido-mount .onfido-sdk-ui-Modal-inner {
	display: flex;
	justify-content: center;
	max-width: 100% !important;
	width: 100%;
	padding: 0px;
	margin: 0px;
	height: 100%;
	position: relative;
}

#onfido-mount .onfido-sdk-ui-NavigationBar-disabled {
	height: 0px;
}

#onfido-mount .onfido-sdk-ui-NavigationBar-navigation{
	height: fit-content;
}

#onfido-mount .onfido-sdk-ui-Theme-footer {
	display: none;
}

#onfido-mount .onfido-sdk-ui-UserConsent-actions {
	padding-top: 1rem;
}

#onfido-mount button.ods-button {
	margin-bottom: 1rem;
}

#onfido-mount .onfido-sdk-ui-NavigationBar-iconBack {
	background-image: url('@/assets/MuiArrowLeftSmall.svg');
}

#onfido-mount .onfido-sdk-ui-Complete-icon {
	background-image: url('@/assets/MuiBigCheckmark.svg');
}


#onfido-mount .onfido-sdk-ui-Theme-step {
	height: 100%;
}

/* onfide back button container, make row direction*/
/* #onfido-mount .onfido-sdk-ui-Theme-step {
	flex-direction: row;

} */

#onfido-mount .onfido-sdk-ui-PageTitle-titleWrapper {
	margin: 0px;
}

/* #onfido-mount .onfido-sdk-ui-Theme-scrollableContent::-webkit-scrollbar {
	display: none; 
} */

/* #onfido-mount .onfido-sdk-ui-Theme-scrollableContent {
	-ms-overflow-style: none; 
	scrollbar-width: none;
	 
}
 */
/*back button */
#onfido-mount .onfido-sdk-ui-NavigationBar-navigation {
	width: 10%;
	/* height: 0px; */
}

/*Onfido change back icon*/
#onfido-mount .onfido-sdk-ui-NavigationBar-iconBack {
	background-image: url('@/assets/MuiBack.svg');
	background-color: transparent;
}

/*Onfido chagne back button, rmeove label*/
#onfido-mount .onfido-sdk-ui-NavigationBar-label {
	display: none;
}

/* @media only screen and (max-width: 600px) {
	#onfido-mount {
		max-height: calc(100vh - 78px);
		position: relative;
	}
} */