.product-card {
	width: 311px;
	height: 192px;
	max-width: 311px;
	min-width: 311px;
	min-height: 192px;
	max-height: 192px;
}

.product-card.account {
	/*aspect-ratio: 16 / 9;*/
	width: 100%;
	height: 100%;
	max-width: unset;
	min-width: unset;
	min-height: unset;
	max-height: unset;
	cursor: pointer;
} 

.product-card .product-card__wrap {
	padding: 30px;
}

.product-card .product-card__lefttBtn {
	border-radius: 16px 0 0 16px;
	width: 139px;
	height: 38px;
}

.product-card .product-card__rightBtn {
	border-radius: 0 16px 16px 0;
	width: 139px;
	height: 38px;
}


.product-card .product-card__singleBtn {
	border-radius: 16px;
	height: 38px;
	width: 100%;
}