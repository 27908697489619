.mui-footer {
    padding: 20px;
    align-items: center;
    justify-content: space-between;
}

.mui-footer-mobile-auth {
    padding: 10px;

}

.mui-footer-mobile-auth div {
    width: 100%;
    text-align: center;
}
