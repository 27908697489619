#statement-download-form .download-type {
	align-content: flex-start;
}

#statement-download-form .download-option {
	justify-content: space-around;
	margin-top: 3rem;
}

#statement-download-form .type-btn-group {
	margin-right: 1rem;
}

#statement-download-form .type-btn {
	height: 2rem;
}

#statement-download-form .MuiFormControl-root {
	width: 100%;
}
