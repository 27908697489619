.welcome-card{
	position: relative;
	max-width: max-content;
	margin: auto;
}
.welcome-card img {
	max-width: 300px;
	width: 100%;
	position: relative;
	z-index: 2;
}
.welcome-card:after{
	content: "";
	position: absolute;
	width: 75%;
	height: 75%;
	top: 50%;
	left: 50%;
	-webkit-transform:  translate(-50%, -50%) rotate(37.9deg);
	transform: translate(-50%, -50%) rotate(37.9deg);
	filter: blur(57px);
	z-index: 1;
}