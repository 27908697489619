.form-template .main-area {
	height: 90%;
}

.form-template .button-area {
	height: 10%;
	align-self: flex-end;
}


.form-multiple-buttons {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	padding-left: 12px;
	padding-right: 12px;
	margin: 0 24px 24px 24px;
}

.form-template__button {
	width: 45%;
	min-height: 48px;
}

.form-template_content-wrap {
	margin: 0 40px 40px 40px;
	height: 100%;
}

.form-template_main-area {
	/*100% - 48px button heigh - 40px margin top -40px marging from buttons till end*/
	height: calc(100% - 128px);
	padding: 1.5rem;
}

/* New styles Wallet v3 */
.form-menu__wrap {
	display: flex;
	flex-direction: column;
	/* margin-left: 2.5rem !important; */
	margin-right: 2.6rem !important;
	padding-top: 0 !important;
}

.form-menu__list {
	margin-top: 32px !important;
}

.form-content__wrap {
	/*height: 100%;*/
	/*margin-bottom: 1rem;*/
	/*height: calc(100% - 27px);*/
}

.form-content__wrap__bb {
	display: flex;
	flex-direction: column;
	flex: 1 1 0;
}

.form-content_main-area {
	display: flex;
	flex: 1;
	flex-direction: column;
	padding: 2.5rem 2rem 0 2rem;

}

.form-content_main-area.wp {
	padding: 0 !important;

}

.form-content_main-area-nopadding {
	display: flex;
	flex: 1;
	flex-direction: column;
	height: 100%;

}


.form-content_botton-area {
	position: relative;
	align-items: flex-end;
	flex-grow: 1;
	min-height: 4rem;
}


.form-content_botton-area .button-left {
	position: absolute;
	bottom: 0;
	left: 0;
	margin-bottom: 2rem;
}

.form-content_botton-area .button-right {
	position: absolute;
	bottom: 0;
	right: 0;
	margin-bottom: 2rem;
}

.form-content_botton-area .button-left__wm {
	position: absolute;
	bottom: 0;
	left: 0;
}

.form-content_botton-area .button-right__wm {
	position: absolute;
	bottom: 0;
	right: 0;
}

.money-form-list-item-text span {
	font-size: 1.125rem !important;
	line-height: 1.625rem !important;
	padding-left: 0 !important;
}

.money-form-list-item-text span .currency {
	font-size: 1.125rem !important;
	line-height: 1.625rem !important;
	font-weight: 500 !important;
	color: #959799 !important;
}

.menu-icon-stroke-size svg path {
	stroke-width: 2;

}