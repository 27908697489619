.portal-hide-scroll-bar {
	-ms-overflow-style: none;
	/* Internet Explorer 10+ */
	scrollbar-width: none;
	/* Firefox */
}

.portal-hide-scroll-bar::-webkit-scrollbar {
	display: none;
	/* Safari and Chrome */
}

.portal-dialog-form-template {
	height: 100%;
	width: 100%;
}


.portal-sroll-box {
	overflow: auto;
}

.policy-sroll-box {
	overflow: hidden;
}

.policy-sroll-box-mobile {
	overflow: auto;
}


.login-logo {
	height: 42px;
	width: auto;
	max-width: 120px;
	cursor: pointer;
}

.login-logo-small {
	height: 40px;
	width: auto;
	max-width: 120px;
	cursor: pointer;
}

.wrapDashboard {
	display: flex;
	height: calc(100vh - 120px);
	flex-direction: column;
	overflow: hidden;
	padding-left: 46px;
}

@media only screen and (max-width: 900px) {
	.wrapDashboard {
		height: calc(100vh - 66px);
		padding: 0px
	}
}

@media only screen and (max-width: 900px) {
	.wrapDashboard {
		padding: 0px
	}
}

.portal-container {
	display: flex;
	flex: 1;
	height: 90vh;
	overflow: auto;
	padding-top: 1rem;
	padding-right: 46px;
}

@media only screen and (max-width: 900px) {
	.portal-container {
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.wrapDashboard {
		padding: 0 0px;
	}
}


@media only screen and (min-width: 900px) {


	.portal-container .content {
		/* max-width: 84%; */
		height: 98%;
	}

}

.portal-container .sidebar {
	flex: 1;
	max-width: 16%;
	height: 98%;
	/*  	height: 88vh;   */
	position: sticky;
	position: -webkit-sticky;
	top: 0;
	align-self: flex-start;
	min-width: 200px;
}

.portal-container .content {
	display: flex;
}

.portal-container .login-content {
	display: flex;
	height: 86vh;
}

.portal-container .policy-content {
	display: flex;
	height: 84vh;
	width: 100%;
}

.portal-container .policy-content-mobile {
	display: flex;
	height: 75vh;
}

.product-details-wrap {
	padding: 24px;
	min-height: 192px;
}

.round-left-top-border .MuiOutlinedInput-root {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
	border-right-style: unset;
}

.round-left-bottom-border .MuiOutlinedInput-root {
	border-top-right-radius: 0;
	border-top-left-radius: 0;
	border-bottom-right-radius: 0;
	border-right-style: unset;
}

.round-right-top-border .MuiOutlinedInput-root {
	border-top-left-radius: 0;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
	border-right-style: unset;
}

.round-right-bottom-border .MuiOutlinedInput-root {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	border-bottom-left-radius: 0;
	border-right-style: unset;
}


.straight-left-border .MuiOutlinedInput-root {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.straight-right-border .MuiOutlinedInput-root {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}


.iconStyleWithGradient {
	/* gradient and fallback color */
	fill: url(#svgIconGradient) #447799;
	height: 20px;
	width: 20px;
}

.service-tab-wrap {
	padding: 1.5rem;
}



.dashboard-content-wrap {
	padding: 2.5rem 1.5rem 1.5rem 2.5rem;
	width: 100%;
	/*height: calc(90vh - 40px);*/
	display: flex;
	flex: 1;
	flex-direction: column;
}

.scroll-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
}

.scroll-grid-container {
	flex-direction: column !important;
	overflow: hidden;
	flex-wrap: nowrap !important;
}



.scroll-section {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	min-height: 0;
}


.scrollable-content {
	flex-grow: 1;
	overflow: auto;
	min-height: 0;
}


.img-icon-size {
	width: 40px;
	height: 40px;
}

.Toastify__toast-container--top-center {
	top: 4em;
}

.Toastify__toast#copied {
	padding: 4px;
	min-height: 40px !important;
}

#policy table {
	width: 100%;
	border-collapse: collapse;
}

#policy td, th {
	border: 1px solid white;
	padding-left: 1rem;
}

.wrapDashboardNormal {
	height: calc(100vh - 54px);
}

@media only screen and (max-width: 900px) {
	.wrapDashboardNormal {
		height: unset;
	}
}