#wallet-portfolio .wallet-portfolio-tabs {
	padding-left: 3rem;
	padding-right: 3rem;
}

@media only screen and (max-width: 700px) {
	#wallet-portfolio .wallet-portfolio-tabs {
		padding-left: 1rem;
		padding-right: 1rem;
	}
}

@media only screen and (min-width: 700px) {
	#wallet-portfolio .wallet-portfolio-tabs {
		padding-left: 2rem;
		padding-right: 2rem;
	}
}
@media only screen and (min-width: 1100px) {
	#wallet-portfolio .wallet-portfolio-tabs {
		padding-left: 3rem;
		padding-right: 3rem;
	}
}
