.card-tab-scroll-container {
	display: flex;
	flex-direction: column !important;
	overflow: hidden;
	flex-wrap: nowrap !important;
	width: 100%;
}

.card-details-container {
	display: flex;
	flex-direction: column !important;
	overflow: hidden;
	width: 100%;
}

.card-details__column {
	min-height: 100%;
	width: 30%;
	display: flex;
	flex-direction: column;
}


.card-trx-container {
	min-height: 30%;
	width: 70%;
	display: flex;
	flex-direction: column;
}

@media only screen and (max-width: 899px) {
	.card-details__column {
		width: 100%;
		min-height: unset;
	}


	.card-trx-container {
		width: 100%;
	}
}


.card-trx-scroll {
	width: 100%;
	min-height: 0;
	display: flex;
	flex: 1;
	flex-direction: column;

}