.banking-product-box {
	width: 220px; 
	height: 116px;
	max-width: 220px; 
	min-width: 220px;
	min-height: 116px;
	max-height: 116px; 
	margin: auto;
}

.card-product-box {
	width: 100%;
	max-width: 100%; 
	min-width: 100%;
	height: 70px;
	min-height: 70px;
	max-height: 70px; 
	margin-bottom: 10px;
	cursor: pointer;
}


.banking-product-box .banking-product-box__item-wrap {
	padding: 1.5rem;
	flex: 1;
	flex-direction: column;
	justify-content: center;   
	width: inherit;
	height: inherit;
}

.card--product-box .card-product-box__item-wrap {
	padding: 1.5rem;
	flex: 1;
	flex-direction: column;
	justify-content: center;   
	width: inherit;
	height: inherit;
}