.fileUploadInput .fileWrap { 
	padding-top: 4px;
	padding-bottom: 1px;
}

.fileUploadInput .fileList { 
	padding-top: 4px;
	padding-bottom: 1px;
	padding-left: 0;
}

.fileUploadInput .fileWrapRow {
	justify-content: end;   
} 

.fileUploadInput .fileListReverse {
	justify-content: end;  
}

.file-drop-box {
	cursor: pointer;
}