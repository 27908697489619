#pair-transactions.trx-content-wrap {  
	flex-grow: 1;  
	display: flex;
	flex-direction: column; 
	min-height: 0;  
	/* height: 100%; */
	width: 100%; 
	height: 20%;
	margin-top: 1rem;
}

#pair-transactions .trx-scroll {
	flex-grow: 1;
	flex-shrink: 1;
	overflow: auto;
	height: 25vh;
	min-height: 100%;  
}